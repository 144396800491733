import React from "react"
import Head from "../../components/Head"

import "../../styles/_layout.scss"
import EduHeader from "../../components/EduPages/EduHeader"
import Footer from "../../components/Footers/Footer"
import EduElems from "../../templates/edu-elems"
import EduFooter from "../../components/EduPages/EduFooter"
import Crossroad from "../../components/Crossroads/Crossroad"
import { PAGE_TITLE } from "../../content/education/constans"
import data from "../../content/education/2020/education.json"
import { intro } from "../../content/education/2020/intro.json"

const PageTitle = PAGE_TITLE[0]

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle + " 2020"} />
    <EduHeader page={{ name: PageTitle }} />

    <main className="main">
      {/* <PageHeader pageTitle={PageTitle} /> */}
      <EduElems data={intro} specify={" edu-section-header"} />
      <Crossroad elems={data} />
      <EduFooter project="2020" />
    </main>

    <Footer project="2020" />
  </>
)

export default IndexPage
